import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { Button } from '@hagerty/react-components';
import { useLocation, WindowLocation } from '@reach/router';
import { Helmet } from 'react-helmet';

import { useAuthContext } from 'src/components/AuthProvider';
import { useByPartnerCustomerIdMembership } from 'src/api/useByPartnerCustomerIdMembership';
import { Translate } from 'src/components/Translate';
import { useTracking, useTrackingContext } from 'src/components/TrackingContext';

const metadata = {
  title: 'Consent Captured | HDC for State Farm',
};

const i18n = {
  title: 'state-farm.consent.captured.title',
  description: 'state-farm.consent.captured.description',
  continue: 'state-farm.consent.continue',
  disclaimer: 'state-farm.disclaimer.footer',
};

const Captured = () => {
  const [callOnce, setCallOnce] = useState(false);
  const { userId } = useAuthContext();
  const { data } = useByPartnerCustomerIdMembership();
  const location = useLocation() as WindowLocation<{ consentEmail: string }>;
  const { trackView, trackInteraction } = useTrackingContext();
  const allowedToSell = data && data.associatedAgent?.allowedToSell;

  useTracking('sf_consent_captured_view', null, true);

  useEffect(() => {
    if (trackView && data && !callOnce) {
      trackView('sf_consent_captured_view', null, {
        virtual_page_name: allowedToSell ? 'captured-can-purchase' : 'captured-cannot-purchase',
      });
      setCallOnce(true);
    }
  }, [trackView, data]);

  const handleClick = () => {
    trackInteraction('button', 'Continue to purchase');
    // Use consent-skipped param because the submitted email from form has already been captured
    navigate(`/checkout/information/?userId=${userId}&consent-skipped=1`, {
      state: { consentEmail: location?.state?.consentEmail },
    });
  };

  return (
    <>
      <Helmet title={metadata.title} />
      <section className="state-farm-container">
        <div className="stack-l">
          <Translate as="h2" resourceKey={i18n.title} />
          <div className="white-space-pre-line">
            <Translate as="p" className="text-lead_2" resourceKey={i18n.description} useHtml />
          </div>
        </div>
        {allowedToSell && (
          <Button children={<Translate resourceKey={i18n.continue} />} buttonSize={'Medium'} onClick={handleClick} />
        )}
      </section>
    </>
  );
};

export default Captured;
